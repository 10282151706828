<template>
  <div class="absolute inset-0 flex items-center justify-center" v-if="!show.id">
    <loading-icon class="h-10 w-10 text-pink-500" />
  </div>
  <div v-else class="relative bg-gray-25">
    <div class="pb-10 lg:pb-20">
      <router-link v-if="showEditButton" :to="{ name: 'GuestDetails' }" class="edit-profile" :style="backgroundColor"
        :class="{ 'gradient': show.color == null }">
        Edit profile
      </router-link>
      <div class="bg-no-repeat bg-center bg-cover py-6 sm:py-8" :style="coverImage">
        <div class="flex flex-col md:flex-row lg:ml-10 xl:ml-20 mt-6 items-center md:items-start">
          <div :style="'background-image: url(' + showImage + ')'"
            class="w-80 h-80 bg-center bg-cover bg-no-repeat relative">
            <button class="rounded-full bg-white absolute top-10 right-0 mt-5 mr-5">
              <FavoriteButton :isFavorited="show.is_favourited" @toggleFavorite="favorite" size='small'
                v-bind="showColorProp('color')" data-html2canvas-ignore />
            </button>
          </div>
          <div
            class="container mx-auto sm:mx-8 lg:ml-16 xl:ml-20 h-full flex flex-col items-center sm:items-start justify-end md:px-6 lg:px-0 md:w-2/3">
            <div v-if="show.showTypes && show.showTypes.length" class="hidden sm:flex items-center space-x-2">
              <a v-for="(type, index) in show.showTypes" :key="index" :href="type.pivot.url || '#'" target="_blank"
                rel="noopener"
                class="font-semibold text-xs rounded-full text-white inline-block py-1 px-5 uppercase tracking-wide"
                :style="`background-color: ${show.color ? show.color : '#6304D1'}`">
                {{ type.name }}
              </a>
            </div>

            <div class="mt-4 text-center sm:text-left">
              <h2 class="text-3xl lg:text-7xl font-bold text-white font-poppins leading-none">
                {{ show.name }}
              </h2>

              <h4 class="mt-3 text-white font-poppins text-lg">
                By {{ this.show.author.name }}
              </h4>
            </div>

            <div class="mt-2 flex flex-col">
              <div class="text-center mt-4 sm:text-left order-last sm:order-first">
                <span v-for="(category, index) in show.categories" :key="index"
                  class="text-white text-lg uppercase font-bold">
                  <span v-if="index > 0"> - </span> <router-link
                    :to="{ name: 'Shows', query: { category: category.id } }" class="hover:text-violet">{{ category.name
                    }}</router-link>
                </span>
              </div>

              <div class="mt-1 flex justify-center sm:justify-start">
                <Rating class="sm:hidden" :with-review-count="false" :ratings="show.ratings" :star-size="22"
                  :avg-rating="show.avg_rating" margin-bottom="mb-0" />
                <Rating class="hidden sm:block" :ratings="show.ratings" :star-size="22" :avg-rating="show.avg_rating"
                  margin-bottom="mb-0" />
              </div>
            </div>

            <div class="hidden mt-8 w-full sm:flex items-end justify-between">
              <template v-if="show.inquiries_only">
                <div class="flex justify-center -mt-3 relative z-10 mb-4">
                  <InquireButton :profile="show" profileType="show" data-html2canvas-ignore />
                </div>
              </template>
              <div v-else class="flex items-center space-x-4">
                <div ref="requestToBookButtonRef"
                  @mouseenter="toggleBookingTooltip('Request To Book', 'requestToBookButtonRef')"
                  @mouseleave="toggleBookingTooltip()">
                  <RequestToBookButton :profile="show" />
                </div>
                <div ref="pitchButtonRef" @mouseenter="toggleBookingTooltip('Pitch', 'pitchButtonRef')"
                  @mouseleave="toggleBookingTooltip()">
                  <PitchButton :profile="show" />
                </div>
                <div ref="bookingTooltipRef" :class="{ 'hidden': !bookingTooltipShow, 'block': bookingTooltipShow }"
                  class="block z-50 mt-2 max-w-sm w-full text-left no-underline break-words rounded-full">

                  <div class="bg-indigo-500 text-white text-xs py-2 px-3 mb-0 rounded-lg absolute">
                    <h2>{{ bookingOptionTooltip }}</h2>
                  </div>
                </div>
              </div>
              <div>
                <ShareProfileButton :profileUrl="profileUrl" />
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="mt-6 sm:hidden">
        <div class="container mx-auto flex flex-col items-center space-y-6">
          <RequestToBookButton :profile="show" class="ml-3 md:ml-0" />
          <PitchButton :profile="show" classes="shadow-xl" />
        </div>
      </div>
    </div>

    <div class="container mx-auto flex flex-wrap lg:flex-no-wrap relative z-10 px-4 md:px-6 lg:px-0">
      <!-- Left col-->
      <div class="w-full lg:w-4/12 lg:mr-20 mb-10 lg:mb-0">
        <div v-if="show.rephonic_stats"
          class="flex justify-between px-5 relative w-full h-14 bg-blue-800 rounded-tr-xl rounded-tl-xl z-10">
          <h1 class="text-white text-sm font-semibold my-auto flex items-center">
            <LockIcon v-if="!showRephonicStats" class="mr-2" />
            Pro stats
          </h1>
          <button v-if="!showRephonicStats" type="button" v-tooltip="'Go Pro to unlock all the stats of this show'">
            <InfoIcon class="h-4 w-4 text-white" />
          </button>
        </div>
        <div :class="{ 'blur': !showRephonicStats }">
          <RephonicStats :currentPodcastStats="show.rephonic_stats" class="bg-white mb-13" rounded-t="" />
        </div>
        <Tags :tags="show.tags" type="shows" />

        <div class="mt-6 flex justify-around md:justify-start" v-if="hasSocialLinks">
          <SocialLinks :socialLinks="show.links.social_media" type="show" />
        </div>

        <MediaKit v-if="show.media_kit_url" :url="show.media_kit_url" />

        <div class="mt-6 hidden lg:block">
          <ApplePodcastsStats :show="show" />
        </div>

        <div class="mt-12 hidden lg:block">
          <!--Reviews-->
          <Reviews :ratings="show.ratings" :avgRating="show.avg_rating" type="show" :userId="show.user_id"
            :profileId="show.id" v-bind="showColorProp('starColor')" />
        </div>
      </div>

      <!-- Right col-->
      <div class="w-full lg:w-8/12 xl:w-9/12 relative">
        <!--Show Stats-->
        <div v-if="filledStats.length" class="mb-12" data-html2canvas-ignore>
          <h4 class="guest-title">Show Stats</h4>

          <div class="grid grid-cols-1 lg:grid-cols-3 gap-6">
            <div v-for="(stat, index) in filledStats" :key="index" class="bg-white rounded-lg px-4 py-4 shadow-4xl">
              <h3 class="text-sm text-grayDark flex items-center space-x-2 font-roboto">
                <span>
                  {{ stat.label }}
                </span>
                <span v-if="stat.verified && stat.visible" v-tooltip="'Verified by Guestio'">
                  <img src="/images/verified_badge.png" class="w-5.5 h-5.5">
                </span>
              </h3>

              <div class="mt-2">
                <span class="text-lg font-bold text-blue-800">
                  {{ stat.value }}
                </span>
              </div>
            </div>
          </div>
        </div>

        <!--Show Booking Options-->
        <div v-if="availableBookingOptions.length" data-html2canvas-ignore>
          <h4 class="guest-title">Booking Options</h4>
          <div class="mb-12 text-sm flex flex-wrap">

            <button v-for="(option, index) in availableBookingOptions" :key="`option-${index}`"
              v-on:mouseenter="toggleTooltip(option.name, index, option.description)" v-on:mouseleave="toggleTooltip()"
              type="button" :ref="`btnRef-${index}`"
              class="mr-4 w-30 mb-4 px-4 py-4 bg-white rounded-xl flex flex-col relative items-center shadow-4xl"
              @click="$router.push({ name: 'ShowBook', params: { showId: show.id, bookingOptionId: option.id } })">
              <div class="flex justify-center h-10 flex-shrink-0">
                <img :src="option.icon_url" class="h-10 w-10">
              </div>

              <div class="flex-1 flex items-center justify-center mt-4 font-semibold text-center text-sm">
                {{ option.name }}
              </div>
            </button>

            <div ref="tooltipRef" :class="{ 'hidden': !tooltipShow, 'block': tooltipShow }"
              class="block z-50 mt-2 max-w-sm w-full text-left no-underline break-words rounded-full">
              <div class="bg-indigo-500 text-white text-xs py-2 px-3 mb-0 rounded-lg absolute">
                <h2>{{ bookingOptionTooltip }}</h2>
              </div>
            </div>

          </div>
        </div>

        <!--About and description-->
        <div>
          <h4 class="text-blue-800 font-bold capitalize text-xl lg:text-2xl mb-4">About {{ show.name }}</h4>
          <p class="text-blue-800 leading-7 overflow-hidden mb-12">{{ show.description }}</p>
        </div>

        <ShowLinks :links="show.links" v-bind="showColorProp('showColor')" />

        <PastGuests :past-guests="show.randomPastGuests" v-bind="showColorProp('showColor')" />

        <div class="mt-12 lg:hidden space-y-12">
          <div>
            <ApplePodcastsStats :show="show" />
          </div>

          <div>
            <!--Reviews-->
            <Reviews :ratings="show.ratings" :avgRating="show.avg_rating" type="show" :userId="show.user_id"
              :profileId="show.id" v-bind="showColorProp('starColor')" />
          </div>
        </div>
      </div>
    </div>
    <HowGuestioWorks />
  </div>
</template>

<script>
import { get, chain } from 'lodash';
import Popper from "popper.js";
import pluralize from 'pluralize';
import api from '@/api';
import FavoriteButton from '@/components/profile/FavoriteButton';
import Rating from '@/components/profile/Rating';
import Tags from '@/components/profile/Tags';
import ShareProfileButton from '@/components/profile/ShareProfileButton';
import ShowLinks from '@/components/profile/ShowLinks';
import Reviews from "@/components/profile/Reviews/index";
import PastGuests from "@/components/profile/PastGuests";
import SocialLinks from '@/components/profile/SocialLinks';
import ApplePodcastsStats from '@/components/profile/ApplePodcastsStats';
import RequestToBookButton from '@/components/profile/RequestToBookButton';
import InquireButton from '@/components/profile/InquireButton';
import PitchButton from '@/components/profile/PitchButton';
import HowGuestioWorks from "@/components/shared/HowGuestioWorks";
import MediaKit from '@/components/profile/MediaKit';
import RephonicStats from '@/components/shared/RephonicStats';
import InfoIcon from '@/components/svgs/InfoIcon'
import LockIcon from '@/components/svgs/LockIcon'
import { mapMutations } from 'vuex';

export default {
  name: "Show",
  components: {
    ShareProfileButton,
    FavoriteButton,
    Rating,
    Tags,
    ShowLinks,
    Reviews,
    PastGuests,
    SocialLinks,
    ApplePodcastsStats,
    RequestToBookButton,
    InquireButton,
    PitchButton,
    HowGuestioWorks,
    MediaKit,
    RephonicStats,
    InfoIcon,
    LockIcon,
  },

  data() {
    return {
      bookingOptionTooltip: '',
      bookingOptions: [],
      tooltipShow: false,
      bookingTooltipShow: false,
      windowWidth: window.innerWidth
    }
  },

  mounted() {
    this.fetchShow();
    window.Event.$on('loggedIn', this.favorite);
    this.fetchBookingOptions();

    window.addEventListener('resize', () => {
      this.windowWidth = window.innerWidth
    });
    this.setPreviousPage('profile');
  },

  methods: {
    ...mapMutations({
      setPreviousPage: "dashboard/bookings/setPreviousPage",
    }),

    fetchShow() {
      this.$store.dispatch('shows/fetchShow', {
        id: this.$route.params.showId,
        includes: 'ratings,links,randomPastGuests,guest,author,showTypes,profileStats,pitch_settings'
      }).then(({ data }) => {
        if (data.author && data.author.isPaused) {
          this.$router.push({ name: 'NotFound' })
        }
      }).catch(error => {
        if (!error.response) {
          return
        }

        console.log(error.response)

        if (error.response.status === 404) {
          this.$router.push({ name: 'NotFound' })
        }
      })
    },

    randomGoodReview() {
      let goodReviews = [];
      let bestReview = 0;
      let bestReviewIdx = 0;
      this.show.ratings.forEach((rating, index) => {
        if (rating.number > 3 && rating.comment) {
          goodReviews.push(index)
        } else if (rating.number > bestReview && rating.comment) {
          bestReviewIdx = index
        }
      });
      if (goodReviews.length) {
        return this.show.ratings[goodReviews[Math.floor(Math.random() * goodReviews.length)]].comment;
      } else {
        return this.show.ratings[bestReviewIdx].comment;
      }
    },

    async favorite() {
      await this.$store.dispatch('dashboard/smartlist/toggleVip', { profileId: this.show.id, type: 'shows' })
      this.fetchShow()
    },

    showColorProp(prop) {
      const result = {};

      if (this.show.color) {
        result[prop] = this.show.color;
      }

      return result;
    },

    async fetchBookingOptions() {
      const { data } = await api.get('/booking-options?filter[type]=show');

      this.bookingOptions = data.data
    },

    toggleTooltip(name, index, description) {
      this.bookingOptionTooltip = description;

      // switch (name) {
      //   case 'Guest Appearance':
      //     this.bookingOptionTooltip = 'Full-length organic interview on this show.';
      //     break;
      //   case 'Sponsor':
      //     this.bookingOptionTooltip = 'Instead of paying for a 30-second ad spot, use this option to book a short form, value-based interview that’s built to promote what you want.';
      //     break;
      //   case 'Pitch':
      //     this.bookingOptionTooltip = 'Use this option if you think you’d be a great fit for this show. If they accept your pitch, this fee will replace the usual booking fee.';
      //     break;
      //   case 'Mock Interview':
      //     this.bookingOptionTooltip = 'Use this feature if you are willing to be booked for a mock podcast interview to help guests improve their quality. We suggest $100 - you keep 80%';
      //     break;
      // }

      if (this.tooltipShow) {
        this.tooltipShow = false;
      } else {
        this.tooltipShow = true;
        new Popper(this.$refs[`btnRef-${index}`][0], this.$refs.tooltipRef, {
          placement: 'bottom-start'
        });
      }
    },

    toggleBookingTooltip(name, ref) {
      this.bookingOptionTooltip = '';

      switch (name) {
        case 'Request To Book':
          this.bookingOptionTooltip = 'Send your guest request and guest appearance fee up front';
          break;
        case 'Pitch':
          this.bookingOptionTooltip = 'Send your guest request for free and allow the host to respond accordingly';
          break;
      }

      if (this.bookingTooltipShow) {
        this.bookingTooltipShow = false;
      } else {
        this.bookingTooltipShow = true;
        new Popper(this.$refs[`${ref}`], this.$refs.bookingTooltipRef, {
          placement: 'bottom-start'
        });
      }
    },
  },

  computed: {
    user() {
      return this.$store.getters['auth/getUser']
    },

    show() {
      return this.$store.getters['shows/show']
    },

    showImage() {
      const defaultImage = require(`@/assets/images/test_image.png`);

      return this.show.imageUrl ? this.show.imageUrl : defaultImage
    },

    reviewsText() {
      return `${this.show.ratings.length} ${pluralize('Review', this.show.ratings.length)}`
    },

    authenticated() {
      return this.$store.getters["auth/authenticated"];
    },

    redirectAfter() {
      return { name: 'Show', params: { showId: this.show.id, favorite: true } }
    },

    hasSocialLinks() {
      return this.show.links && Object.values(this.show.links.social_media).filter(link => link != null).length
    },

    frontendUrl() {
      return process.env.VUE_APP_URL
    },

    profileUrl() {
      return `${this.frontendUrl}/shows/${this.show.slug ? this.show.slug : this.show.id}`
    },

    showEditButton() {
      if (!this.user || !this.user.shows) {
        return false;
      }

      if (!this.user.shows.length) {
        return
      }

      return !!this.user.shows
        .filter(show => show.id == this.show.id)
        .length
    },

    showRephonicStats() {
      return this.user && this.user.account_type === 'pro'
    },

    backgroundColor() {
      return this.show.color ? `background-color: ${this.show.color}` : ''
    },

    coverImage() {
      let background = '';

      if (this.show.coverImageUrl && this.windowWidth >= 1024) {
        background += `background-image: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, #000000 100%), url('${this.show.coverImageUrl}')`;
      } else if (this.windowWidth < 1024) {
        background += `background-image: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, #000000 100%), url('${this.show.imageUrl}')`;
      } else {
        background += 'background: #000000'
      }

      return background;
    },

    optionPrices() {
      return get(this.show, 'option_prices', [])
    },

    allBookingOptions() {
      if (!this.optionPrices.length || !this.bookingOptions.length) {
        return []
      }

      return chain(this.optionPrices)
        .map(price => {
          const option = this.bookingOptions.find(o => o.id == price.booking_option_id);

          return {
            id: option.id,
            icon_url: option.icon_url,
            name: option.name,
            order_column: option.order_column,
            price: price.price,
            slug: option.slug,
            description: option.description
          }
        })
        .uniqBy('id')
        .sortBy('order_column')
        .value()
    },

    availableBookingOptions() {
      return this.allBookingOptions.filter(option => option.slug != 'pitch');
    },

    filledStats() {
      return this.show.profileStats.filter(stat => stat.label && stat.value)
    },
  },

  destroyed() {
    this.$store.commit('shows/clearShow')
    window.Event.$off('loggedIn')
  }
};
</script>

<style scoped lang="scss">
.bg-backgroundGray {
  background-color: #FAFAFA !important;
}

.show-image {
  height: 310px;
}

@media (max-width: 1390px) {
  .container {
    max-width: 1024px;
  }
}

.gradient {
  @apply bg-gradient-to-r from-indigo-500 to-pink-500;
}

.edit-profile {
  @apply text-white absolute z-40 right-0 m-5 transition duration-300 ease-in-out border rounded-full px-6 py-2 text-xs font-medium opacity-50;

  &:hover {
    @apply opacity-100;
  }
}

.blur {
  -webkit-filter: blur(5px);
  -moz-filter: blur(5px);
  -o-filter: blur(5px);
  -ms-filter: blur(5px);
  filter: blur(5px);
  background-color: #ccc;
}
</style>
